import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row } from '../../components/Grid';
import Image from '../../components/Image';
import Hero from '../../components/Hero';
import Subfooter from '../../components/Subfooter';
import EpisodePlayer from '../../components/EpisodePlayer';
import bgImage from '../../images/img-bg-lines-video-topic-series.png';
import '../../styles/pages/_educators.scss';

const VideoTopicSeries = ({ data }) => {
  return (
    <Layout title="Video Topic Series">
      <Hero
        mediaSource="img-lead-educators.jpg"
        textOverlay={{
          heading: 'Video Topic Series',
          pageTitle: 'Educators',
        }}
      />
      <Section
        className="pb-0 video-topic-series__episode-player-section"
        style={{
          backgroundImage: `url("${bgImage}")`,
          backgroundOrigin: 'border-box',
          backgroundPositionY: '109.5%',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          overflowY: 'visible',
        }}
      >
        <Container fullWidth>
          <Row>
            <EpisodePlayer
              className="pt-0"
              playerLabelTitle="Problem Solvers for Good"
              playerLabelSubTitle="Making the World a Better Place Through Engineering"
              episodes={data.allVideoTopicsYaml.edges.map((edge) => edge.node)}
            />
          </Row>
        </Container>
      </Section>
      <Section className="pb-0 pt-0">
        <Subfooter />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query VideoTopicsQuery {
    allVideoTopicsYaml {
      edges {
        node {
          id
          actions {
            label
            path
            type
          }
          guidSrc
          posterSrc
          introTitle
          introBody
          descriptionTitle
          description
          title
          innovatorSpotlightTitle
          innovatorSpotlightBody
          innovatorSpotlightQuote
          innovatorSpotlightAttribution
        }
      }
    }
  }
`;

export default VideoTopicSeries;
