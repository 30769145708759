import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Row, Column } from '../Grid';
import Video from '../Video';
import Action from '../Resource/Action';
import Image from '../Image';
import './styles.scss';

const EpisodePlayer = ({
  episodes,
  playerLabelTitle,
  playerLabelSubTitle,
  className,
}) => {
  const [activeIndex, setActiveIndex] = useState(0); // zero-based index
  const viewerRef = useRef(null);

  const renderEpisodes = () => (
    <>
      <h5 className="mb-0.5 mt-0">Video stories of problem-solvers for good</h5>
      {episodes.map((episode, index) => (
        <button
          key={index}
          className="episode-player__episode"
          onClick={() => {
            setActiveIndex(index);
            if (viewerRef.current.getBoundingClientRect()['top'] < 0) {
              viewerRef.current.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        >
          <div
            className={classNames(
              index === activeIndex && 'episode-player__episode--active'
            )}
          >
            <Image filename={episode.posterSrc} className="episode__image" />
          </div>
          <div>
            <h5>{episode.introTitle}</h5>
          </div>
        </button>
      ))}
    </>
  );

  const renderCurrentVideo = () => {
    const episode = episodes[activeIndex];
    return (
      <Video
        guidSrc={episode.guidSrc}
        poster={episode.posterSrc}
        className="video-player"
        title={episode.title}
      />
    );
  };

  const renderCurrentData = () => {
    const episode = episodes[activeIndex];
    return (
      <Row>
        <Column size={8}>
          <div className="episode-player__intro pb-2">
            <h2 className="episode-player__title">{episode.title}</h2>
            <h3>{episode.introTitle}</h3>
            <p>{episode.introBody}</p>
            <h3>{episode.descriptionTitle}</h3>
            <p>{episode.description}</p>
            <h3>{episode.innovatorSpotlightTitle}</h3>
            <p>{episode.innovatorSpotlightBody}</p>
            {episode.actions && (
              <div className="episode-player__actions">
                {episode.actions.map((action, i) => (
                  <Action key={i} download={action.path} label={action.label} />
                ))}
              </div>
            )}
          </div>
        </Column>
        <Column size={4}>
          <div className="episode-player__quote-body pt-1">
            {episode.innovatorSpotlightQuote}
          </div>
          <div className="episode-player__quote-attribution">
            <div>—</div>
            {episode.innovatorSpotlightAttribution}
          </div>
        </Column>
      </Row>
    );
  };

  return (
    <Container fullWidth className={`episode-player ${className} pb-0`}>
      <Row className="mb-0.5">
        <Column>
          <h2 className="episode-player__player-label-title mb-0">
            {playerLabelTitle}
          </h2>
        </Column>
      </Row>
      <Row className="pt-0">
        <Column>
          <h3 className="episode-player__player-label-subtitle">
            {playerLabelSubTitle}
          </h3>
        </Column>
      </Row>
      <Row>
        <Column size={8}>
          <div ref={viewerRef} className="episode-player__video">
            {renderCurrentVideo()}
          </div>
        </Column>
        <Column size={4}>
          <div className="episode-player__episodes">{renderEpisodes()}</div>
        </Column>
      </Row>
      <div className="episode-player__current-data pb-2">
        {renderCurrentData()}
      </div>
    </Container>
  );
};

EpisodePlayer.propTypes = {
  episodes: PropTypes.array.isRequired,
  playerLabelTitle: PropTypes.string,
  playerLabelSubTitle: PropTypes.string,
  className: PropTypes.string,
};

EpisodePlayer.defaultProps = {
  playerLabel: '',
  className: '',
};

export default EpisodePlayer;
